import * as React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"

const TextInputStyles = {
    width: "60%",
    outline: "none",
    padding: "0.5em",
    marginTop: "0.5em",
    marginBottom: "0.5em",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    borderRadius: "0.3em",
    border: "2px solid #aaa",
    boxShadow: "0 0 1px #000",
    transition: "border-color 300ms",
    "&:hover": {
        borderColor: "#000"
    },
    "&:focus": {
        borderColor: "#1976D2"
    },
    "@media(max-width: 900px)": {
        width: "80%"
    },
    "@media(max-width: 400px)": {
        width: "90%"
    }
}

const TextInput = styled.input(TextInputStyles)

const TextArea = styled.textarea({
    ...TextInputStyles,
    resize: "none",
    minHeight: "8em",
})

const SubmitButton = styled.button({
    display: "block",
    marginTop: "0.8em",
    marginBottom: "0.9em",
    marginLeft: "auto",
    marginRight: "auto",
    paddingRight: "0.7em",
    paddingLeft: "0.7em",
    paddingTop: "0.4em",
    paddingBottom: "0.4em",
    cursor: "pointer",
    borderRadius: "0.3em",
    border: "2px solid #aaa",
    transition: "border-color 300ms, background-color 300ms",
    backgroundColor: "#ddd",
    "&:hover": {
        borderColor: "#1976D2",
        backgroundColor: "#bbb"
    },
})

const Privacy = styled.div({
    margin: "1.2em auto",
    display: "block",
    width: "60%",
    textAlign: "center",
    "@media(max-width: 500px)": {
        width: "80%"
    }
})

const Checkbox = styled.input({
    marginRight: "0.4em"
})

const PrivacyLink = styled(Link)({
    textDecoration: "underline",
    color: "#000",
    marginLeft: "0.3em"
})

export default function ContactForm() {
    return (
        <form action="https://formspree.io/f/xleoqpgq" method="POST">
            <TextInput type="email" name="_replyto" placeholder="Correo electrónico" required />
            <TextInput type="text" name="subject" placeholder="Asunto" required />
            <TextArea name="message" placeholder="Mensaje" required></TextArea>
            <Privacy>
                <Checkbox type="checkbox" name="accept" required />
                Estoy de acuerdo con la
                <PrivacyLink to="/privacy">política de privacidad</PrivacyLink>
            </Privacy>
            <SubmitButton type="submit">Enviar</SubmitButton>
        </form>
    )
}

import * as React from "react"
import MainContainer from "../components/MainContainer"
import ContactForm from "../components/ContactForm"
import PageHeader from "../components/PageHeader"
import { useStaticQuery, graphql } from "gatsby"
import Separator from "../components/misc/Separator"
import SEO from "../components/SEO"

export default function ContactPage() {

    const { header } = useStaticQuery(query)
    const { title, paragraphs, seoTitle, seoDescription } = header

    return (
        <MainContainer>
            <SEO title={seoTitle} description={seoDescription} pagename="contact" />
            <PageHeader title={title} paragraphs={paragraphs}>
                <ContactForm />
            </PageHeader>
            <Separator height="5em" />
        </MainContainer>
    )
}

const query = graphql`
    query {
        header: headersJson(id: {eq: "contact"}) {
            title
            paragraphs
            seoTitle
            seoDescription
        }
    }
`
